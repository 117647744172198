import React from 'react'
import '../assets/scss/FeaturedProperties.scss'
import { Card, Col, Image, Row } from 'react-bootstrap'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom'

function FeaturedProperties() {
    const properties = [
        {
            id: '1',
            image: 'https://kodinyumba.app/media/Stock/hd/Property/apartment1.jpg',
            name: 'Sunny Villa',
            type: 'Villa',
            address: '123 Main Street, Nairobi, Kenya',
            bed: '4',
            bath: '3',
            rent: "25,000",
            space: 'Sqft: 2500',
        },
        {
            id: '2',
            image: 'https://kodinyumba.app/media/Stock/hd/Property/apartment2.jpg',
            name: 'City View Apartments',
            type: 'Apartment',
            address: '456 Elm Avenue, Mombasa, Kenya',
            bed: '2',
            bath: '2',
            rent: "12,000",
            space: 'Sqft: 1200',
        },
        {
            id: '3',
            image: 'https://kodinyumba.app/media/Stock/hd/Property/apartment3.jpg',
            name: 'Lakefront Mansion',
            type: 'Mansion',
            address: '789 Lake Drive, Nakuru, Kenya',
            bed: '6',
            bath: '5',
            rent: "50,000",
            space: 'Sqft: 5000',
        },
        {
            id: '4',
            image: 'https://kodinyumba.app/media/Stock/hd/Property/apartment4.jpg',
            name: 'Cozy Apartment',
            type: 'Villa',
            address: '10 Riverside Road, Nairobi, Kenya',
            bed: '2',
            bath: '1',
            rent: "15,000",
            space: 'Sqft: 5280',
        },
        {
            id: '5',
            image: 'https://kodinyumba.app/media/Stock/hd/Property/apartment5.jpg',
            name: 'Garden View Apartments',
            type: 'Apartment',
            address: '20 Palm Street, Mombasa, Kenya',
            bed: '2',
            rent: "15,000",
            bath: '1',
            space: 'Sqft: 5280',
        },
        {
            id: '6',
            image: 'https://kodinyumba.app/media/Stock/hd/Property/apartment6.jpg',
            name: 'Downtown Studio',
            type: 'Studio Apartments',
            address: '30 Beach Road, Nakuru, Kenya',
            bed: '2',
            bath: '1',
            rent: "15,000",
            space: 'Sqft: 5280',
        },
    ];



    const settings = {
        dots: true,
        infinite: true,
        speed: 1000, // Adjust the speed as needed
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className='featuredProperties bg-light'>
            <h2>Featured Properties</h2>
            <p>Handpicked properties by our team.</p>
            <Link to={`/listing-page/All`} className='viewALL'>View all</Link>

            {/* the display cards of the properties */}
            <Row>
                <Slider {...settings}>
                    {properties.map((property) => (

                        <Col md={4} lg={3} sm={6} xm={12} xl={2}>
                            <Card>
                                <Link to={`/selected-property/${property.id}`} className='text-decoration-none text-dark'>
                                    <Card.Body>
                                        <Image src={property.image} className='propertyImage' />
                                        <div className='d-flex'>

                                            <div className='d-flex topFeaturedButtons'>
                                                <span className='topFeaturedProperties'>Featured</span>
                                                <span className='topRentProperties'>For Rent</span>
                                            </div>

                                            <div className='d-flex topFeaturedButtonsLast'>
                                                <span className='topRentProperties'>1 Br</span>
                                                <span className='topRentProperties'>4 Br</span>

                                            </div>
                                        </div>



                                        <span className='textRentProperty'>Ksh. {property.rent}/ month</span>
                                        <h6>{property.type}</h6>
                                        <p className='fw-bold'>{property.name}</p>

                                        <p style={{ fontSize: '0.75rem' }}>
                                            <i className='bi bi-geo-alt'></i>
                                            <span>{property.address}</span>
                                        </p>
                                        {/* 
                                    <div className='d-flex justify-content-between' style={{ fontSize: '0.75rem' }}>
                                        <p>Beds:{property.bed}</p>
                                        <p>Baths:{property.bath}</p>
                                        <p>{property.space}</p>
                                    </div> */}

                                        {/* <div className='d-flex justify-content-between'>
                                        <div className='d-flex'>
                                            <Image src={property.user_image} className='userImage' />
                                            <p className='ms-2' style={{ marginTop: '0.2rem' }}>{property.user_name}</p>
                                        </div>
                                        <p style={{ marginTop: '0.2rem' }}>{property.created}</p>
                                    </div> */}

                                    </Card.Body>
                                </Link>
                            </Card>
                        </Col>


                    ))}
                </Slider>


            </Row>

        </div>
    )
}

export default FeaturedProperties