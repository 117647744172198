import React from 'react'
import '../assets/scss/WhyUs.scss'
import { Col, Card, Row, Image } from 'react-bootstrap'
import Why1 from '../assets/images/why_1.jpg'
import Why2 from '../assets/images/why_2.jpg'
import Why3 from '../assets/images/why_3.jpg'
import Person1 from '../assets/images/person_1.png'
import Person2 from '../assets/images/person_2.png'
import Person3 from '../assets/images/person_3.png'


function WhyUs() {
    return (
        <div className='bg-light whyus'>
            <h3 className='fw-bold'>Why Choose Us</h3>
            <p>We provide full service at every step</p>

            <Row className='mt-5 rowWhyUs'>
                <Col md={4}>
                    <Card>
                        <Card.Body>
                            <span><i class="bi bi-rocket-takeoff-fill"></i></span>
                            <h2>Trusted By Thousands</h2>
                            <p>
                                We've earned the confidence of countless individuals and families on their journey to finding their ideal homes. 
                                Our commitment to excellence and customer satisfaction is the cornerstone of our success. Join the ranks of those who have placed their trust in us for
                                 all their real estate needs.
                            </p>
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={4}>
                    <Card>
                        <Card.Body>
                            <span><i class="bi bi-buildings-fill"></i></span>
                            <h2>Wide Range Of Properties</h2>
                            <p>We showcases a diverse selection of properties that cater to every tenant's taste and preference. Whether you're in search of a cozy apartment in the heart of the city,
                                 a spacious suburban family home, a serene countryside retreat, or a prime commercial space for your business, we have a wide array of options to choose from.</p>
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={4}>
                    <Card>
                        <Card.Body>
                            <span><i class="bi bi-cash-stack"></i></span>
                            <h2>Financing Made Easy</h2>
                            <p>Securing the right financing for your property just got simpler with Alliance Realtors. Our team of experts ensures a hassle-free experience, offering personalized guidance, 
                                competitive rates, and complete transparency. With fast approvals and diverse financing options, we're your trusted partner on the path to property ownership.</p>
                        </Card.Body>
                    </Card>
                </Col>

            </Row>

            <h3 className='fw-bold mt-5'>Articles & Tips</h3>
            <p>Explore our curated collection of insightful articles and expert tips for all things real estate.</p>

            <Row className='mt-5 rowArticles'>
                <Col md={4}>
                    <Card>
                        <Card.Body>
                            <Image src={Why1} />
                            <h2>Trusted By Thousands</h2>
                            <p>
                            We've earned the confidence of countless individuals and families on their journey to finding their ideal homes
                            </p>
                        </Card.Body>

                        <Card.Footer>
                            <div className='footerWhyUs'>
                                <Image src={Person1} />
                                <p>Peter Mwangi</p>
                            </div>

                            <p>07 September 2023</p>
                        </Card.Footer>
                    </Card>
                </Col>

                <Col md={4}>
                    <Card>
                        <Card.Body>
                            <Image src={Why2} />
                            <h2>Wide Range Of Properties</h2>
                            <p>
                            We showcases a diverse selection of properties that cater to every tenant's taste and preference.
                            </p>
                        </Card.Body>

                        <Card.Footer>
                            <div className='footerWhyUs'>
                                <Image src={Person2} />
                                <p>Faith Mwende</p>
                            </div>

                            <p>09 August 2023</p>
                        </Card.Footer>
                    </Card>
                </Col>

                <Col md={4}>
                    <Card>
                        <Card.Body>
                            <Image src={Why3} />
                            <h2>Financing Made Easy</h2>
                            <p>Securing the right financing for your property just got simpler with Alliance Realtors. </p>
                        </Card.Body>

                        <Card.Footer>
                            <div className='footerWhyUs'>
                                <Image src={Person3} />
                                <p>Phylis Nyokabi</p>
                            </div>

                            <p>19 August 2023</p>
                        </Card.Footer>
                    </Card>
                </Col>

            </Row>

        </div>
    )
}

export default WhyUs