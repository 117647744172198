import React from 'react'
import { Button, Card, Dropdown, Tab, Tabs } from 'react-bootstrap'
import '../assets/scss/HomepageSearch.scss'
import { Link } from 'react-router-dom'
function HomePageSearch() {
    return (
        <div className='homepageSearch'>
            <Tabs
                defaultActiveKey="profile"
                id="uncontrolled-tab-example"
                className="mb-3"
            >
                <Tab eventKey="home" title="Buy" className='me-3'>
                    <Card style={{ marginInline: "2rem" }} >
                        <Card.Body className=''>
                            <input type='text' placeholder='Enter Keyword..' />
                            <input type='text' placeholder='Property Type' />
                            <input type='text' placeholder='Location' />
                            <input type='number' placeholder='Price' />


                            <Dropdown>
                                <Dropdown.Toggle
                                    id="dropdown-basic"
                                    className="advanceButtonSearch custom-dropdown-toggle"
                                >
                                    Advanced
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="#" className="text-muted border-bottom">
                                        Search
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>


                            <Button
                                className='btn border-0 text-decoration-none d-flex justify-content-center align-items-center searchButtonHome'
                            
                            >
                                <Link to={'/listing-page/All'} className='text-decoration-none text-white'>
                                    Search
                                </Link>
                            </Button>

                        </Card.Body>
                    </Card>
                </Tab>

                <Tab eventKey="profile" title="Rent">
                    <Card style={{ marginInline: "2rem" }} >
                        <Card.Body className=''>
                            <input type='text' placeholder='Enter Keyword..' />
                            <input type='text' placeholder='Property Type' />
                            <input type='text' placeholder='Location' />
                            <input type='number' placeholder='Price' />

                            <Dropdown className='btn-sm'>
                                <Dropdown.Toggle
                                    id="dropdown-basic"
                                    className="advanceButtonSearch custom-dropdown-toggle"
                                >
                                    Advanced
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="#" className="text-muted border-bottom">
                                        Search
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Button
                                className='btn border-0 text-decoration-none d-flex justify-content-center align-items-center searchButtonHome'
                            >
                                <Link to={'/listing-page/All'} className='text-decoration-none text-white'>
                                    Search
                                </Link>
                            </Button>
                        </Card.Body>
                    </Card>
                </Tab>

            </Tabs>

        </div>
    )
}

export default HomePageSearch