import React, { Component } from 'react';
import { Map, Marker, GoogleApiWrapper} from 'google-maps-react';
import SpecialNavigation from '../components/SpecialNavigation';
import MapImage from '../assets/images/listings.png'
import Footer from '../components/Footer';
import { Nav, Navbar, NavDropdown, Container, Image, Dropdown, Modal, Button, Form, InputGroup } from 'react-bootstrap';
import TopMenuFilter from '../components/TopMenuFilter';
import {Link} from 'react-router-dom'

class HouseLocation extends Component {
  render() {
    const { google, location } = this.props;

    return (
      <>
        <SpecialNavigation />
        <TopMenuFilter/>
        <div style={{ width: '100%', height: '100%', marginTop: "10rem" }}>
          <Map
            google={google}
            zoom={14} // Adjust the initial zoom level as needed
            initialCenter={location}
            style={{ width: '88%', marginInline: "6rem", marginTop: "2rem", height: '70vh' }} 
          >
            <Marker
              position={location}
              name={'House Location'}
            />
          </Map>
        </div>
        <Button className='text-white bg-dark border-0 bottomButtonMap'>
        <Link to={'/listing-page/All'} className='text-white text-decoration-none'>
        Show Listings
        </Link>
        <Image src={MapImage} style={{ width: "20%", marginLeft: "0.4rem" }} />
             
        </Button>
        <div className='mapFooter'>
        <Footer />

        </div>

      </>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDx1JG3xfwVxYr6q7RrjevNC1tCuxfxXd8',
})(HouseLocation);
