import React, { useState, useEffect } from 'react'
import { Image, Card, FormSelect, Button, Row, Col, Form, FormControl, Modal, Dropdown, ModalBody, ModalHeader, ModalTitle } from 'react-bootstrap';
import '../assets/scss/SelectedProperty.scss'
import SideBar from '../components/SideMenu'
import Footer from '../components/Footer'
import NavigationBar from '../components/NavigationBar';
import { serverUrl } from '../serverUrl';
import { useParams, Link } from "react-router-dom";
import SpecialNavigation from '../components/SpecialNavigation';
import Heart from '../assets/images/heart__1_.png'
import Heart2 from '../assets/images/heart.png'
import Star from '../assets/images/star.png'
import Map from '../assets/images/map.png'
import Grid from '../assets/images/grid.png'

import TopMenuFilter from '../components/TopMenuFilter';
import Slider from 'react-slick';
import { Carousel } from 'react-bootstrap';


function SelectedProperty() {
    const { id } = useParams();

    // loading status
    const [isLoading, setIsLoading] = useState(true)

    // apartment
    const [selectedApartment, setSelectedApartment] = useState([])
    const [apartmentImage, setApartmentImage] = useState("")

    // houses of the user
    const [house, setHouse] = useState([])

    const [houseSetImage, setHouseSetImage] = useState(null)


    const [landlordPreviousOccupancy, setLandlordPreviousOccupancy] = useState(0);
    const [landlordCurrentOccupancy, setLandlordCurrentOccupancy] = useState(0);
    const [apartmentExpectedIncome, setApartmentExpectedIncome] = useState(0);
    const [apartmentReceivedIncome, setApartmentReceivedIncome] = useState(0);
    // const [amenitiesApartment, setAmenitiesApartment] = useState([])

    // LIST OF AMENITIES


    // randomise for each House
    const [backgroundImages, setBackgroundImages] = useState({});

    const amenitiesApartment = [
        {
            name: "Court",
            icon: `https://kodinyumba.app/media/Icons/2023/06/21/basketball-court.png`
        },
        {
            name: "CCTV",
            icon: `https://kodinyumba.app/media/Icons/2023/06/21/cctv.png`
        },
        {
            name: "WiFi",
            icon: `https://kodinyumba.app/media/Icons/2023/06/21/wifi_1.png`
        },
        {
            name: "Balcony",
            icon: `https://kodinyumba.app/media/Icons/2023/06/21/balcony.png`
        },
        {
            name: "Gym",
            icon: `https://kodinyumba.app/media/Icons/2023/06/21/gym.png`
        },
    ]


    const [houseDummy, setHouseDummy] = useState([]);

    useEffect(() => {
        // Generate random data when the component mounts
        const generateRandomData = () => {
            const newHouseDummy = [];
            for (let i = 1; i <= 30; i++) {
                const rentAmount = 15_000 + i * 500;
                newHouseDummy.push({
                    house_id: `${i}`,
                    name: `A0${i}`,
                    rent: rentAmount.toLocaleString(),
                    ratings: `${Math.floor(Math.random() * 5) + 0.5}`,
                    rent_type: ['Residential', 'Commercial'][Math.floor(Math.random() * 2)],
                    type_rent_status: ['Rent', 'Rent To Own', 'Purchase'][Math.floor(Math.random() * 3)],
                    bedrooms: `${Math.floor(Math.random() * 4) + 1}`,
                    bathroom: `${Math.floor(Math.random() * 3) + 1}`,
                    type: ['Apartment Units', 'Studio', 'Bungalow', 'Bedistter', 'Servant Quarters(SQ)', 'Mansionette', 'Office', 'Stall', 'Shop',][Math.floor(Math.random() * 9)],
                });
            }
            setHouseDummy(newHouseDummy);
        };

        // Call the function to generate random data when the component mounts
        generateRandomData();
    }, []);


    useEffect(() => {


        const getRandomImage = () => {
            const usedImages = Object.values(backgroundImages);
            const availableImages = Array.from({ length: 21 }, (_, index) => index + 1);
            const remainingImages = availableImages.filter((image) => !usedImages.includes(image));
            const randomNumber = remainingImages[Math.floor(Math.random() * remainingImages.length)];
            const imageUrl = `https://kodinyumba.app/media/Stock/House/house${randomNumber}.jpg`;
            return imageUrl;
        };

        const updateBackgroundImages = () => {
            const updatedImages = { ...backgroundImages };
            // Loop through each house and update the image if not set
            for (const unitDetail of houseDummy) {
                const houseId = unitDetail.house_id;
                if (!updatedImages[houseId]) {
                    updatedImages[houseId] = getRandomImage();
                }
            }
            setBackgroundImages(updatedImages);
            setIsLoading(false);
        };

        updateBackgroundImages();
    }, [houseDummy]);


    // randomise the images for the apartment
    const [backgroundImageApartment, setBackgroundImageApartment] = useState('');

    useEffect(() => {
        const getRandomImage = () => {
            let storedImage = localStorage.getItem('backgroundImage');
            if (!storedImage) {
                storedImage = '';
            }

            let randomNumber = Math.floor(Math.random() * 10) + 1;
            const invalidNumbers = [0, -1];
            while (invalidNumbers.includes(randomNumber) || storedImage.endsWith(`${randomNumber}.jpg`)) {
                randomNumber = Math.floor(Math.random() * 10) + 1;
            }

            const imageUrl = `https://kodinyumba.app/media/Stock/hd/Property/apartment${randomNumber}.jpg`;
            setBackgroundImageApartment(imageUrl);
            localStorage.setItem('backgroundImage', imageUrl);
            setIsLoading(false);
        };

        getRandomImage();
    }, []);

    // design the table to display 10 each per table
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 12;
    const filteredData = houseDummy;
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = currentPage === totalPages - 1 ? filteredData.length : startIndex + itemsPerPage;
    const displayedItems = filteredData.slice(startIndex, endIndex);

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    // apartment background image
    const backgroundImage = apartmentImage || backgroundImageApartment;

    // house carousel
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    useEffect(() => {
        const getRandomImages = () => {
            const usedImages = Object.values(backgroundImageApartment);
            const availableImages = Array.from({ length: 20 }, (_, index) => index + 1);
            const uniqueRandomImages = [];
            let randomNumber = Math.floor(Math.random() * 20) + 1;

            while (uniqueRandomImages.length < 5) {
                const randomNumber = Math.floor(Math.random() * availableImages.length);
                const randomImage = availableImages.splice(randomNumber, 1)[0];

                if (!usedImages.includes(randomImage)) {
                    uniqueRandomImages.push(randomImage);
                }
            }

            return uniqueRandomImages.map((imageNumber) =>
                `https://kodinyumba.app/media/Stock/House/house${imageNumber}.jpg`
            );
        };

        const updateBackgroundImages = () => {
            const updatedImages = { ...backgroundImageApartment };

            for (const unitDetail of houseDummy) {
                const apartmentId = unitDetail.house_id;
                if (!updatedImages[apartmentId]) {
                    updatedImages[apartmentId] = getRandomImages();
                }
            }
            setBackgroundImageApartment(updatedImages);
        };

        updateBackgroundImages();
    }, [houseDummy, backgroundImageApartment]);

    const renderCarouselItems = (apartmentId) => {
        const uniqueImages = backgroundImageApartment[apartmentId] || [];

        return uniqueImages.map((imageUrl, index) => (
            <Slider {...settings} key={index}>

                <Image src={imageUrl} alt={`Image ${index + 1}`} fluid />

            </Slider>
        ));
    };
    const [showHeart1, setShowHeart1] = useState(false);
    const [showHeart2, setShowHeart2] = useState(true);

    const toggleHearts = (event) => {
        event.preventDefault();
        setShowHeart1(!showHeart1);
        setShowHeart2(!showHeart2);
    };

    // randomise the images for my properties
    const [backgroundImageProperty, setBackgroundImageProperty] = useState([]);

    useEffect(() => {
        const getRandomImages = () => {
            const images = [];
            for (let i = 1; i <= 5; i++) {
                let randomNumber = Math.floor(Math.random() * 10) + 1;
                while (randomNumber === 0 || randomNumber === -1 || images.includes(randomNumber)) {
                    randomNumber = Math.floor(Math.random() * 10) + 1;
                }
                images.push(randomNumber);
            }

            const imageUrls = images.map((randomNumber) => `https://kodinyumba.app/media/Stock/hd/Property/apartment${randomNumber}.jpg`);
            setBackgroundImageProperty(imageUrls);
        };

        getRandomImages();
    }, []);
    const [showModal, setShowModal] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    const handleImageClick = (index) => {
        setSelectedImageIndex(index);
        setShowModal(true);
    };

    return (
        <>
            <SpecialNavigation />
            <TopMenuFilter />

            <div className='dashboard_topheader'>

            </div>
            {/* <SideBar /> */}




            <div className='selected-property'>
                <div className='topSelectedProperty'>
                    {backgroundImageProperty.length > 0 && (
                        <Carousel >
                            {backgroundImageProperty.map((imageUrl, index) => (
                                <Carousel.Item key={index}>
                                    <img
                                        src={imageUrl}
                                        alt={`Image ${index}`}
                                        className="propertyCarousel"
                                        onClick={() => handleImageClick(index)}
                                    // style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                    />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    )}
                    <div className='ImageHouseName'>
                        {selectedApartment && (<>
                            <span className='properties'>Ruaraka</span>
                            <h2>Johari Apartments</h2>
                            <Button className='text-white bg-dark border-0 bottomButtonMap'>
                                <Link to={'/map'} className='text-white text-decoration-none'>Show Map</Link>
                                <Image src={Map} style={{ width: "20%", marginLeft: "0.2rem" }} />
                            </Button>
                            <Button className='btn btn-primary btn-sm w-10 text-white selectedPropertyShowAll' style={{ height: "auto", position: "relative", padding: "8px", width: "13%", marginTop: "4rem", marginRight: "0.5rem" }}><Image src={Grid} style={{ width: "12%" }} /> Show all photos</Button>
                        </>)}
                    </div>
                </div>
                <Modal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    centered
                    dialogClassName='modal-100w' // Add custom CSS class
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Apartment View Gallery</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Carousel
                            activeIndex={selectedImageIndex}
                            onSelect={(index) => setSelectedImageIndex(index)}
                        >
                            {backgroundImageProperty.map((image, index) => (
                                <Carousel.Item key={index}>
                                    <Image src={image} alt={`Image ${index}`} fluid />
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='secondary' onClick={() => setShowModal(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                {/* check if the apartment has an image */}

                <div style={{ backgroundImage: `url(${backgroundImageProperty})` }}>
                    {/* <div className='ImageHouseName'>
                        {selectedApartment && (<>
                            <span className='properties'>Ruaraka</span>
                            <h2>Johari Apartments</h2>
                            <Button className='text-white bg-dark border-0 bottomButtonMap' href='/map' style={{ position: "relative", margin: "0 auto", left: "0", width: "13%", zIndex: "0" }}>Show map
                                <Image src={Map} style={{ width: "20%", marginLeft: "0.2rem" }} />
                            </Button>
                        </>)}
                    </div> */}
                </div>

                <div className='d-flex w-25 mt-5 justify-content-between ButtonsTopAmenities'>
                    <Button>Ammenities</Button>
                    <Button>Nearby Facilities</Button>
                </div>
                <Row>

                    <Col className='d-flex' style={{ height: 'fit-content',flexWrap:"wrap" }}>

                        {displayedItems.map((unitDetail, index) =>
                            <>
                                <Card className='house'>
                                    <Card.Body>
                                        <Link to={`/selected-house/${unitDetail.house_id}`} className='text-decoration-none'>
                                            <div className="image-container">
                                                {/* Display the image of the house */}

                                                {/* <Image
                                                    src={backgroundImages[unitDetail.house_id]}
                                                    className="houseUnit"
                                                    alt="default image"
                                                /> */}
                                                <Slider {...settings} interval={null} className="houseUnit">{renderCarouselItems(unitDetail.house_id)}</Slider>

                                                <div className="badge">{unitDetail.rent_type}</div>

                                                <div className='heartBadge' onClick={toggleHearts}>
                                                    {showHeart1 && <img src={Heart} alt="Heart" />}
                                                    {showHeart2 && <img src={Heart2} alt="Heart2" />}
                                                </div>
                                                <div className="badge_rent">{unitDetail.type_rent_status}</div>
                                                <div className='d-flex justify-content-between'>
                                                    <p className="text-start houseTitle" style={{ marginBottom: "0" }} >{unitDetail.name}</p>
                                                    <div className='d-flex justify-content-end w-100' style={{ height: "7.5vh" }}>
                                                        <Image src={Star} className='' style={{ width: "8%", height: "30%" }} />
                                                        <p className='text-secondary fw-bold' style={{ fontSize: "0.9rem", marginLeft: "0.2rem" }}>{unitDetail.ratings}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="houseRentP">Ksh {unitDetail.rent}/month</div>

                                            <p className="houseName fw-normal" style={{ marginBottom: "20px", position: "absolute", bottom: "6%" }}>{unitDetail.type?.toLowerCase()}</p>

                                        </Link>
                                        <div className="d-flex" style={{ paddingTop: "0.8rem" }}>
                                            <span className='d-flex' style={{ marginTop: ":1rem" }}>
                                                <span style={{ fontSize: "0.8rem", marginRight: "0.4rem", marginTop: ":1rem" }}>{unitDetail.bedrooms} Bedrooms</span>
                                                <span style={{ fontSize: "1.8rem", marginTop: "-1.2rem" }}>.</span>
                                                <span style={{ fontSize: "0.8rem", marginLeft: "0.4rem" }}>{unitDetail.bathroom} Bathroom</span>

                                            </span>


                                        </div>




                                    </Card.Body>

                                </Card>

                            </>

                        )}

                    </Col>
                    <div className="pagination" style={{ margin: '0 auto', padding: "2rem", width: 'inherit' }}>
                        <button onClick={handlePrevPage} disabled={currentPage === 0}>
                            &lt;
                        </button>
                        <span style={{ alignSelf: "center" }}>{`${startIndex + 1}-${endIndex} of ${houseDummy.length}`}</span>
                        <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                            &gt;
                        </button>
                    </div>
                </Row>

                {/* amenities modal */}
                <Modal>
                    <ModalHeader className='fw-bold mb-2 fs-6 text-start' style={{ color: "#1F57F8 " }}>
                        <ModalTitle>AMENITIES</ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <Card className='topProperty'>

                            <Row>
                                {amenitiesApartment.length > 0 ?
                                    amenitiesApartment.map((amenity) => (
                                        <Col md={12} className="amenties text-start d-flex">
                                            <Image src={amenity.icon} style={{ width: "15%", marginBottom: "1rem" }} />
                                            <p className='ms-3 amenityText'> {amenity.name}</p>
                                        </Col >
                                    ))
                                    :
                                    <p style={{ color: "#95a5a6" }}>No amenities found for this property kindly add one</p>
                                }


                            </Row>
                        </Card>
                    </ModalBody>

                </Modal>


            </div >
            <Footer />


        </>
    )
}

export default SelectedProperty