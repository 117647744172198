import React from 'react'
import { Routes, Route, BrowserRouter, HashRouter } from 'react-router-dom'
import Home from './pages/Home'
import ListingPage from './pages/ListingPage'
import SelectedProperty from './pages/SelectedProperty'
import SelectedHouse from './pages/SelectedHouse'
import HouseLocation from './pages/HouseLocation'
import Allphotos from './pages/Allphotos'

function Webroutes() {
    return (

            <HashRouter>
                <Routes>
                    <Route exact path="/" Component={Home} />
                    <Route exact path="/listing-page/:id" Component={ListingPage} />
                    <Route exact path="/selected-property/:id" Component={SelectedProperty} />
                    <Route exact path="/selected-house/:id" Component={SelectedHouse} />
                    <Route exact path="/map" Component={HouseLocation} />
                    <Route exact path="/all-photos" Component={Allphotos} />


                </Routes>
            </HashRouter>


    )
}

export default Webroutes