import React from 'react'
import '../assets/scss/OurPartners.scss'
function OurPartners() {
  return (
    <div className='partners'>
      <h2>Our Partners</h2>
      
      <p>We only work with the best companies around the globe</p>
    </div>
  )
}
export default OurPartners
