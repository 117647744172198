import React, { useState, useEffect } from 'react'
import { Link, useParams } from "react-router-dom";
import { Button, Card, Col, Row, Image, Modal, Form, Dropdown, Carousel, Container } from 'react-bootstrap';
import '../assets/scss/SelectedHouse.scss'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import NavigationBar from '../components/NavigationBar';
// auth imports
import { useNavigate } from "react-router";
import Footer from '../components/Footer';

import HouseLocation from './HouseLocation';
import SpecialNavigation from '../components/SpecialNavigation';
import Star from '../assets/images/star.png'
import Grid from '../assets/images/grid.png'
import User from '../assets/images/logo_1.png'
import Person from '../assets/images/person_1.png'
import Person1 from '../assets/images/person_2.png'
import Top from '../assets/images/office-desk.png'
import Bottom from '../assets/images/swimming.png'
import MapComponent from '../components/MapComponent';


function SelectedHouse() {
    const reviews = [
        {
            name: 'Mark',
            image: Person,
            date: 'August 2023',
            content:
                'If you are looking for a place that offers tranquility, beauty, and convenience, this 2 - bedroom apartment is it.I had the most wonderful time living here, and I would recommend it to anyone looking to experience the magic of coastal Kenya.',
        },
        {
            name: 'Victoria',
            image: Person1,
            date: 'February 2023',
            content:
                'The apartment complex was well-maintained and secure, making me feel safe and comfortable throughout my stay. The neighbors were friendly, adding to the sense of community.',
        },
        {
            name: 'Mark',
            image: Person,
            date: 'August 2023',
            content:
                'If you are looking for a place that offers tranquility, beauty, and convenience, this 2 - bedroom apartment is it.I had the most wonderful time living here, and I would recommend it to anyone looking to experience the magic of coastal Kenya.',
        },
        {
            name: 'Victoria',
            image: Person1,
            date: 'February 2023',
            content:
                'The apartment complex was well-maintained and secure, making me feel safe and comfortable throughout my stay. The neighbors were friendly, adding to the sense of community.',
        },
        {
            name: 'Mark',
            image: Person,
            date: 'August 2023',
            content:
                'If you are looking for a place that offers tranquility, beauty, and convenience, this 2 - bedroom apartment is it.I had the most wonderful time living here, and I would recommend it to anyone looking to experience the magic of coastal Kenya.',
        },
        {
            name: 'Victoria',
            image: Person1,
            date: 'February 2023',
            content:
                'The apartment complex was well-maintained and secure, making me feel safe and comfortable throughout my stay. The neighbors were friendly, adding to the sense of community.',
        },

    ];

    const houseLocation = { lat: 37.7749, lng: -122.4194 };

    const { id } = useParams();
    // const [showModal, setShowModal] = useState(false);
    const [showModalHouse, setShowModalHouse] = useState(false)
    const [showModalRate, setShowModalRate] = useState(false);

    // modal for editing the details of the tenant
    const [showModalEdit, setShowModalEdit] = useState(false)
    const [houseTenant, setHouseTenant] = useState([])
    const [joined, setJoined] = useState("")
    const [currentDate, setCurrentDate] = useState(new Date().toLocaleDateString());
    // the current date format in yyyy-mm-dd
    let formatDate = new Date().toISOString().split("T")[0]

    // remove tenant
    const [showModalDeleteTenant, setShowModalDeleteTenant] = useState(false);
    const [tenantRemove, setTenantRemove] = useState(null);
    const [tenantIdOccupancy, setTenantIdOccupancy] = useState(null);
    const [houseId, setHouseId] = useState(null);

    const handleOpenDeleteTenant = () => {
        setShowModalDeleteTenant(true);
    };
    const handleCloseModalDeleteTenant = () => {
        setShowModalDeleteTenant(false);
    };
    const handleCloseModalReloadTenantDelete = () => {
        setShowModalDeleteTenant(false);
        window.location.reload()
    };
    // loading status
    const [isLoading, setIsLoading] = useState(true)


    // form data for tenant details
    const [formDataTenant, setFormDataTenant] = useState({
        full_name: "",
        phone_number: ""
    });
    const [landlord, setLandlord] = useState("")

    // house selected
    // const [houseSelected, setHouseSelected] = useState([])
    const [houseSelectedApartment, setHouseSelectedApartment] = useState("")
    const [houseApartment, setHouseApartment] = useState("")


    // for kodi score purpose
    const [tenantScore, setTenantScore] = useState("")
    const [tenantId, setTenantId] = useState("")
    const [occupancySet, setOccupancy] = useState({})
    const [rentStatus, setRentStatus] = useState({})

    const houseSelected = [
        {
            id: "2",
            name: "A01",
            occupancy_status: "vacant",
            house_number: 'A01',
            rent: "10,000",
            about: 'Nestled in the heart of the enchanting coastal town of Ukunda in Mombasa, Kenya, a splendid opportunity awaits you to experience the epitome of tropical tranquility. Welcome to your dream 2-bedroom apartment, perched gracefully on the second floor of a picturesque apartment complex, where every sunrise and sunset is a masterpiece painted by Mother Nature herself.',
            type_of_house: "Bedistter",
            bathroom: "1",
            bedroom: "2",


        }
    ]

    // the functionality for the carousel 

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 3000,
        // fade:true,

    };
    const textSet = [
        'Bedroom',
        'Bathroom',
        'Kitchen',
        'Living Room',
        'Master Bedroom',
    ];
    const settingsSpecial = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        appendDots: (dots) => (
            <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
                <ul style={{ margin: '0' }}>{dots}</ul>
            </div>
        ),
    };

    // Get the house object with the matching id
    // const imageDemo = [House6, House7, House8];
    const imageDemo = [];

    const RoomImages = [
        {
            image: "https://kodinyumba.app/media/Stock/House/house13.jpg",
            name: "Bedroom"
        },
        {
            image: "https://kodinyumba.app/media/Stock/House/house1.jpg",
            name: "Bathroom"
        },
        {
            image: "https://kodinyumba.app/media/Stock/House/house2.jpg",
            name: "Kitchen"
        },
        {
            image: "https://kodinyumba.app/media/Stock/House/house3.jpg",
            name: "Living Room"
        }
    ]

    // Setting up the image
    const [currentImage, setCurrentImage] = useState(0);
    const [imageLength, setImageLength] = useState(0);
    const [imagesUnit, setImagesUnit] = useState([]);

    const [backgroundImages, setBackgroundImages] = useState({});
    const [imageSet, setImageSet] = useState([]);

    useEffect(() => {
        const getRandomImage = () => {
            const usedImages = Object.values(backgroundImages);
            const availableImages = Array.from({ length: 21 }, (_, index) => index + 1);
            const remainingImages = availableImages.filter((image) => !usedImages.includes(image));
            const randomNumber = remainingImages[Math.floor(Math.random() * remainingImages.length)];
            const imageUrl = `https://kodinyumba.app/media/Stock/House/house${randomNumber}.jpg`;
            return imageUrl;
        };

        const updateBackgroundImages = () => {
            const updatedImages = { ...backgroundImages };
            const selectedHouses = [];

            // Generate random images for the first three houses
            for (let i = 0; i < 4; i++) {
                const imageUrl = getRandomImage();
                selectedHouses.push(imageUrl);

                // Update the updatedImages object
                updatedImages[i] = imageUrl;
            }

            // Push the selected images into imageDemo
            imageDemo.push(...selectedHouses);
            setImageSet(imageDemo);


            setBackgroundImages(updatedImages);
            setIsLoading(false);
        };

        updateBackgroundImages();

        // Set up the carousel to cycle through images
        const interval = setInterval(() => {
            setCurrentImage((prevImage) => (prevImage + 1) % imageDemo.length);
        }, 5000); // Change the interval as needed

        return () => clearInterval(interval); // Clean up the interval
    }, []);




    const selectedUnit = houseSelected[0]
    const idHouse = parseInt(id)

    const [showSidebar, setShowSidebar] = useState(true);
    function toggleSidebar() {
        setShowSidebar(!showSidebar);
    }
    // modal for adding tenant

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    // done button reloading the page
    const handleCloseModalReload = () => {
        setShowModal(false);
        window.location.reload()
    };

    // modal for editing the details of the tenant

    const handleOpenModalEdit = () => {
        setShowModalEdit(true);
    };

    const handleCloseModalEdit = () => {
        setShowModalEdit(false);
    };
    const handleCloseModalEditReload = () => {
        setShowModal(false);
        window.location.reload()
    };
    // counting the number of digits inserted on the phone number
    const digitCount = formDataTenant.phone_number.replace(/[^0-9]/g, "").length;
    // the data that will be posted to the api is as follows



    const handleOpenModalRate = () => {
        setShowModalRate(true);
    };

    const handleCloseModalRate = () => {
        setShowModalRate(false);
    };
    // done button reloading the page
    const handleCloseModalReloadRate = () => {
        setShowModalRate(false);
        window.location.reload()
    };
    // form data for tenant details ratings
    const [formDataTenantRate, setFormDataTenantRate] = useState({
        comment: "",
        rating: ""
    });

    // onchange functionality on rating tenant
    const handleChangeTenantRate = (event) => {
        const { name, value } = event.target;

        setFormDataTenantRate((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));

    }
    // the stars value
    const [starsClicked, setStarsClicked] = useState(0);

    useEffect(() => {
        // console.log("Value of stars:", starsClicked === 0 ? 0 : starsClicked);
    }, [starsClicked]);

    const handleStarClick = (starIndex) => {
        if (starIndex === 0) {
            setStarsClicked(0);
        } else if (starsClicked === starIndex) {
            setStarsClicked(starIndex - 1);
        } else {
            setStarsClicked(starIndex);
        }
    };

    // onchange functionality on adding tenant
    const handleChangeTenant = (event) => {
        const { name, value } = event.target;
        // limit phone number input to 10 digits
        if (name === "phone_number") {
            const digitOnly = value.replace(/[^0-9]/g, "");
            setFormDataTenant((prevFormData) => ({
                ...prevFormData,
                [name]: digitOnly.substring(0, 10),
            }));
        } else {
            setFormDataTenant((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }
    }



    // form data to be sent to backend on eadding the House
    const [formDataHouse, setFormDataHouse] = useState({
        house_number: "",
        total_rooms: "",
        due_date: "",
        bedroom: "",
        deposit: "",
        bathroom: "",
        rent: "",
        type_of_house: "",
    });
    // onchange functionality on editing a  house
    const handleChangeHouse = (event) => {
        const { name, value } = event.target;
        setFormDataHouse((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    }


    // now display the stars based on the score they have obtained

    const scoreParts = tenantScore.split("/");
    const scoreKodi = scoreParts[0];
    const dividedScore = parseFloat(scoreParts[0]) / 2;

    const starCount = Math.floor(dividedScore);
    // give them default half stars if for their score
    const hasHalfStar = dividedScore < 1;

    const stars = [];
    for (let i = 0; i < 5; i++) {
        if (i < starCount) {
            stars.push(<i className='bi bi-star-fill'></i>);
        } else if (i === starCount && hasHalfStar) {
            stars.push(<i className='bi bi-star-half'></i>);
        } else {
            stars.push(<i className='bi bi-star'></i>);
        }
    }

    // when user clicks call then open the call
    const handleClickPhone = () => {
        window.location.href = `tel:${houseTenant.phone_number}`;
    };

    // send reminder for the landlord
    const [remindersModal, setRemindersModal] = useState(false)

    // modal for filtering on the month
    const handleReminderModalOpen = () => {
        setRemindersModal(true);
    };

    const handleReminderModalClose = () => {
        setRemindersModal(false);
    };
    const handleReminderModalReload = () => {
        setRemindersModal(false);
        window.location.reload()
    };

    const [selectedOptionReminder, setSelectedOptionReminder] = useState(null);
    const [addTemplate, setAddTemplate] = useState(false);

    const handleOptionSelect = (option) => {
        setSelectedOptionReminder(option);
        setAddTemplate(false)
    };

    const handleAddTemplate = () => {
        setSelectedOptionReminder('')
        setAddTemplate(true)
    }
    const [showModal, setShowModal] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    const handleAmenitiesOpen = () => {
        setShowModal(true);
    };
    const handleAmenitiesClose = () => {
        setShowModal(false);
    };
    const essentials = [
        {
            name: "Parking",
            image: "https://kodinyumba.app/media/Icons/2023/06/21/signage.png"
        },
        {
            name: "Wifi",
            image: "https://kodinyumba.app/media/Icons/2023/06/21/wifi_1.png"
        },
        {
            name: "Laundry Services",
            image: "https://kodinyumba.app/media/Icons/2023/06/21/washing.png"
        },
        {
            name: "Clothing Line",
            image: "https://kodinyumba.app/media/Icons/2023/06/21/drying.png"
        },
        {
            name: "Swimming Pool",
            image: "https://kodinyumba.app/media/Icons/2023/06/21/swimming-pool.png"
        },
        {
            name: "Air Conditioning",
            image: "https://kodinyumba.app/media/Icons/2023/06/21/air-conditioner.png"
        },
        {
            name: "24 Hour Security",
            image: "https://kodinyumba.app/media/Icons/2023/06/21/guard.png"
        },
        {
            name: "Elevator",
            image: "https://kodinyumba.app/media/Icons/2023/06/21/elevator.png"
        }
    ]
    const nearby = [
        {
            name: "Church",
            distance: "120m",
            image: "https://kodinyumba.app/media/church.png"
        },
        {
            name: "Hospital",
            distance: "500m",
            image: "https://kodinyumba.app/media/hospital.png"
        },
        {
            name: "Mall",
            distance: "640m",
            image: "https://kodinyumba.app/media/shopping-center.png"
        },
        {
            name: "School",
            distance: "20m",
            image: "https://kodinyumba.app/media/school.png"
        },
        {
            name: "Mosque",
            distance: "120m",
            image: "https://kodinyumba.app/media/mosque.png"
        },
        {
            name: "Tarmac Road",
            distance: "50m",
            image: "https://kodinyumba.app/media/road.png"
        },
        {
            name: "Police Station",
            distance: "400m",
            image: "https://kodinyumba.app/media/police-station.png"
        }
    ]

    return (
        <>
            <SpecialNavigation />

            {/* <Slider {...settings} className="imageDemo">
                {imageSet.slice(0, 4).map((imageUrl) => (
                    <div key={imageUrl} className="selectedHouseCarousel">
                        <img src={imageUrl} alt="carousel slide" />
                    </div>
                ))}
            </Slider> */}
            <div className='selectedHouseImageContent'>

                <h3>A03, Johari apartments</h3>
                <div className='d-flex justify-content-between'>
                    <div className='d-flex' style={{ width: "45%" }}>
                        <p style={{ width: "7.5%", marginRight: "0.4rem" }}><Image src={Star} className='' style={{ width: "35%", marginBottom: "0.2rem" }} /> 3.6</p>
                        <p style={{ width: "2%", marginTop: "-0.8rem", fontSize: "1.5rem" }}>. </p>
                        <Link className='text-dark' style={{ fontSize: "0.9rem", marginRight: "0.5rem" }}> 2 reviews</Link>
                        <p style={{ marginRight: "0.5rem", marginTop: "-0.8rem", fontSize: "1.5rem" }}>.</p>
                        <Link className='text-dark' style={{ fontSize: "0.9rem" }}>Ukunda,Kwale County,Kenya</Link>

                    </div>
                    <div className='d-flex  justify-content-between'>
                        <Link className='text-dark' style={{ fontSize: "0.9rem", marginRight: "1.5rem" }}><i class="bi bi-upload"></i>  Share</Link>
                        <Link className='text-dark' style={{ fontSize: "0.9rem" }}><i class="bi bi-heart"></i>  Save</Link>

                    </div>
                </div>

                <Row className='imageSelectedHouseRow'>
                    <Col md={6} sm={12} xm={12} className='topImageleft'>
                        <Image src='https://kodinyumba.app/media/Stock/House/house12.jpg' />
                    </Col>

                    <Col md={6} sm={12} xm={12} >
                        <Row className='topImageright'>
                            <Col md={6} sm={12} xm={12} className='topImagerightChild'>
                                <Image src='https://kodinyumba.app/media/Stock/House/house2.jpg' />
                            </Col>
                            <Col md={6} sm={12} xm={12} className='topImagerightChild' >
                                <Image src='https://kodinyumba.app/media/Stock/House/house1.jpg' style={{ borderRadius: "0px 20px 0px 0px" }} />
                            </Col>
                        </Row>
                        <Row className='bottomImageright'>
                            <Col md={6} sm={12} xm={12} className='bottomImagerightChild' >
                                <Image src='https://kodinyumba.app/media/Stock/House/house13.jpg' />
                            </Col>
                            <Col md={6} sm={12} xm={12} className='bottomImagerightChild'>
                                <Image src='https://kodinyumba.app/media/Stock/House/house5.jpg' style={{ borderRadius: "0px 0px 20px 0px" }} />
                            </Col>
                            <div className='d-flex' style={{ justifyContent: "right" }}>
                                <Button className='btn btn-primary btn-sm w-10 text-white' href='/all-photos' style={{ width: "25%", height: "4vh", marginTop: "-2rem" }}
                                ><Image src={Grid} style={{ width: "12%" }} /> Show all photos</Button>
                            </div>


                        </Row>
                    </Col>
                </Row>

                <div>
                    <Row>
                        <Col md={6} sm={12} xm={12}>
                            <div className='d-flex justify-content-between' style={{ borderBottom: "1px solid lightgray" }}>
                                <div>
                                    <h5>Mansionete</h5>
                                    <div className='d-flex border-bottom-1 mb-2 text-secondary' style={{ fontSize: "0.9rem" }}>
                                        <p className='mb-1 me-2'>2 bedrooms </p>
                                        <p className='mb-1' style={{ marginTop: "-0.8rem", fontSize: "1.5rem" }}> . </p>
                                        <p className='mb-1 ms-2'> 3 bathrooms</p>
                                    </div>
                                </div>
                                <Image src={User} style={{ width: "8%", height: "64%" }} />
                            </div>
                            <div style={{ borderBottom: "1px solid lightgray" }}>
                                <div className='mt-2 d-flex p-2'>
                                    <Image src={Top} style={{ width: "6%", height: "6vh" }} />
                                    <div className='ms-4'>
                                        <h6>Dedicated workspace</h6>
                                        <p style={{ fontSize: "0.8rem" }} className='text-muted'>A room that’s well-suited for working.</p>
                                    </div>
                                </div>
                                <div className='mt-1 d-flex p-2'>
                                    <Image src={Bottom} style={{ width: "6%", height: "6vh" }} />
                                    <div className='ms-4'>
                                        <h6>Dive right in</h6>
                                        <p style={{ fontSize: "0.8rem" }} className='text-muted'>This is one of the few places in the area with a pool.</p>
                                    </div>
                                </div>
                            </div>

                        </Col>

                        <Col md={6} sm={12} xm={12}>
                            <Card className='w-75 ms-auto' style={{ boxShadow: "6px 2px 12px lightgray" }}>
                                <Card.Body>
                                    <div className='d-flex justify-content-between'>
                                        <span style={{ fontSize: "0.7rem" }}><b className='fs-6'>Ksh.20,000</b> /month</span>
                                        <p className='d-flex' style={{ width: "9.5%", marginLeft: "38%" }}><Image src={Star} className='' style={{ width: "50%", height: "70%", marginRight: "0.2rem" }} /> 3.6 </p>
                                        <p style={{ fontSize: "1.8rem", marginTop: "-1rem" }}>.</p>
                                        <Link style={{ fontSize: "0.8rem", marginTop: "0.2rem", color: "black" }}>2 reviews</Link>
                                    </div>
                                    <Row style={{ padding: "0.8rem" }}>
                                        <Col md={6} className='dateColDisplay' style={{ borderRadius: "10px 0px 0px 0px" }}>
                                            <h6 style={{ fontSize: "0.8rem" }}>Date Posted</h6>
                                            <p style={{ fontSize: "0.7rem " }}>10/02/2023</p>
                                        </Col>
                                        <Col md={6} className='dateColDisplay' style={{ borderRadius: "0px 10px 0px 0px" }}>
                                            <h6 style={{ fontSize: "0.8rem" }}>Type</h6>
                                            <p style={{ fontSize: "0.7rem " }}>FOR RENT</p>
                                        </Col>
                                        <Col md={12} className='dateColDisplayLast'>
                                            <h6 style={{ fontSize: "0.8rem" }}>Past Tenants</h6>
                                            <p style={{ fontSize: "0.7rem " }}>2 Tenants</p>
                                        </Col>
                                    </Row>

                                    <Button className='btn mt-2  btn-primary text-white d-flex m-auto w-100 justify-content-center' style={{ height: "3.2rem", paddingTop: "0.8rem" }}>Book Viewing</Button>
                                </Card.Body>
                            </Card>
                        </Col>

                    </Row>

                </div>

                {houseSelected.map((house) => (

                    <div className='descriptionSelectedHouse' >

                        <div className='p-3'>
                            <h5 className='float-left'>Description</h5>
                            {house.about === null ?
                                <p className='descriptionHouseSelected' style={{ display: "flex", textWrap: "wrap", width: "49%", fontSize: "0.9rem" }}>About status is empty</p>
                                :
                                <p className='descriptionHouseSelected' style={{ display: "flex", textWrap: "wrap", width: "49%", fontSize: "0.9rem" }}>{house.about}</p>
                            }
                        </div>
                        <p style={{ borderBottom: "1px solid lightgray", width: "50%", textAlign: "left", marginBottom: "0" }}></p>

                    </div >

                ))}

                <div className='roomImages'>
                    <div className='' style={{ padding: '1.2rem' }}>
                        <h5>Room Images</h5>

                        <Slider {...settingsSpecial} className="imageDemo w-50 p-0">
                            {RoomImages.map((imageUrl, index) => (
                                <div key={imageUrl} className="selectedHouseCarousel ">
                                    <img src={imageUrl.image} alt="carousel slide" style={{ padding: "0", width: "19vw", borderRadius: "10px", height: "35vh", marginTop: "1rem" }} />
                                    <p className='mt-2'>{imageUrl.name}</p>
                                </div>
                            ))}
                        </Slider>
                    </div>

                    <p className='descriptionHouseSelected' style={{ borderBottom: "1px solid lightgray", width: "50%", textAlign: "left" }}></p>

                </div>


                <div>
                    <Row className='p-2'>
                        <Col md={6}>
                            <h5>What this unit offers</h5>
                            <Row>
                                {essentials.map((essential) => (
                                    <Col md={6} sm={12} xm={12} className='d-flex'>
                                        <Image src={essential.image} style={{ width: "10%", height: "65%", marginTop: "0.9rem", marginLeft: "0.7rem" }} />
                                        <label style={{ marginTop: "0.8rem", marginLeft: "1.5rem" }}>{essential.name}</label>
                                    </Col>

                                ))}
                            </Row>
                            <Button className='btn btn-outline-primary bg-transparent text-dark w-auto mb-3 mt-3 d-flex' onClick={handleAmenitiesOpen}>Show all 34 amenities</Button>
                            <Modal
                                show={showModal}
                                onHide={handleAmenitiesClose}
                                centered
                                dialogClassName='modal-100w'
                            >
                                <Modal.Header closeButton>
                                </Modal.Header>
                                <Modal.Body>
                                    <Row>
                                        {essentials.map((essential) => (
                                            <Col md={6} sm={12} xm={12} className='d-flex'>
                                                <Image src={essential.image} style={{ width: "10%", height: "65%", marginTop: "0.9rem", marginLeft: "0.7rem" }} />
                                                <label style={{ marginTop: "0.8rem", marginLeft: "1.5rem" }}>{essential.name}</label>
                                            </Col>

                                        ))}
                                    </Row>

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant='secondary' onClick={handleAmenitiesClose}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </Col>

                    </Row>

                </div>

                <div style={{ borderTop: "1px solid lightgray", borderBottom: "1px solid lightgray" }}>
                    <div className='p-2'>
                        <h5 className='mt-2' >2 reviews</h5>
                        <p className='text-muted'>Average rating will appear after 3 reviews</p>
                        <Slider {...settings}>
                            {reviews.map((review, index) => (
                                <div key={index} className="carousel-item w-75">
                                    <div className='d-flex'>
                                        <Image src={review.image} style={{ width: "10%", borderRadius: "50%", marginRight: "0.8rem" }} />

                                        <div>
                                            <h5 className='mb-0'>{review.name}</h5>
                                            <span className='text-muted' style={{ fontSize: "0.8rem" }}>{review.date}</span>
                                        </div>
                                    </div>

                                    <p style={{ fontWeight: "0.8rem" }} className='text-muted mt-2'>{review.content}</p>
                                </div>
                            ))}
                        </Slider>
                    </div>

                </div>

                <div className='locationMap' style={{ borderBottom: "1px solid lightgray", marginTop: "1.5rem" }}>
                    <h5 className='mt-2' style={{ marginBottom: "1.5rem" }} >Where you will be</h5>

                    <MapComponent />
                    <p></p>
                </div>


                <div>
                    <Row className='mt-2' style={{ borderBottom: "1px solid lightgray" }}>
                        <Col md={6} className='p-3'>
                            <h5>Nearby Facilities</h5>
                            <Row>
                                {nearby.map((essential) => (
                                    <Col md={6} sm={12} xm={12} className='d-flex'>
                                        <Image src={essential.image} style={{ width: "10%", height: "55%", marginTop: "0.9rem", marginLeft: "0.7rem" }} />
                                        <label style={{ marginTop: "0.8rem", marginLeft: "1.5rem" }}><span>{essential.name}</span> is {essential.distance} away</label>

                                    </Col>

                                ))}


                            </Row>
                        </Col>

                    </Row>

                </div>

                {/* <div>
                    <Row className='mt-2' style={{ borderBottom: "1px solid lightgray" }}>
                        <Col md={6} className='p-3'>
                            <h5>Agent Management</h5>

                            <div className='d-flex mb-2'>
                                <Image src={Person} style={{ width: "9%", borderRadius: "50%" }} />
                                <p style={{ fontSize: "0.8rem", marginLeft: "0.5rem", marginTop: "1rem" }}>Jirani Management Sacco</p>
                            </div>
                            <p style={{ fontSize: "0.8rem" }}>We have managed properties since 1994 and currently we are managing 300 properties.</p>
                            <Link to='#' style={{ fontSize: "0.8rem" }}>www.jiranisaccomanagement.com</Link>

                        </Col>

                    </Row>

                </div> */}
                <div>
                    <Row className='mt-3' style={{ borderBottom: "1px solid lightgray" }}>
                        <h5 style={{ marginBottom: "1rem", marginTop: "1rem" }}>Things To Know</h5>
                        <Col md={4} sm={12} xm={12} style={{ fontSize: "1.0rem" }}>
                            <h6>Property Rules</h6>
                            <ul className='text-secondary'>
                                <li>No pets allowed</li>
                                <li>No students allowed</li>
                                <li>No loud music past 10:00pm</li>

                            </ul>
                        </Col>
                        <Col md={4} sm={12} xm={12} style={{ fontSize: "1.0rem" }}>
                            <h6>Safety</h6>
                            <ul className='text-secondary'>
                                <li>Gate closes at Midnight</li>
                                <li>Visitors should be registered at the gate</li>
                                <li>None residents are not allowed in the Laundry area</li>
                            </ul>
                        </Col>
                        <Col md={4} sm={12} xm={12} style={{ fontSize: "1.0rem" }}>
                            <h6>Rental Policy</h6>
                            <ul className='text-secondary'>
                                <li>Deposits to be paid before occuping the house</li>
                                <li>Deposits are returned only if the unit is still in good condition as it was before user occupied</li>
                                <li>Payments to be done through paybill only, no cash allowed</li>

                            </ul>
                        </Col>

                    </Row>

                </div>

            </div >


            {houseSelected.map((house) => (

                <div className=''>


                    {/* <SelectedHouseCarousel houseId={selectedUnit.id} /> */}


                    {/* <div className=' mb-3 mt-3'>
                        <div className='d-flex ms-5 ps-5'>
                            <Button className='btn btn-sm btn-success text-capitalize' >{house.occupancy_status}</Button>
                            <Button className='btn btn-sm btn-warning text-white' style={{ marginLeft: "3rem" }}>Tenant Agreement</Button>

                        </div>
                    </div> */}

                </div >

            ))}
            < p ></p >
            <Footer />

        </>
    )
}

export default SelectedHouse