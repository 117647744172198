import React, { useState, useEffect } from 'react';
import { Nav, Navbar, NavDropdown, Container, Image, Dropdown, Modal, Button, Form, InputGroup } from 'react-bootstrap';
import '../assets/scss/NavigationBar.scss';
import Logo from '../assets/images/logo_alliance.png'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { Link, useLocation } from 'react-router-dom';
import '../assets/scss/SpecialNavigation.scss'
function SpecialNavigation() {
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        setScrollPosition(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // the dropdown login part
    const [showLoginDropdown, setShowLoginDropdown] = useState(false);
    const [showUserLoginModal, setShowUserLoginModal] = useState(false);

    const handleLoginClick = () => {
        setShowLoginDropdown(!showLoginDropdown);
    };

    const handleUserLoginClick = () => {
        setShowUserLoginModal(true);
        setShowLoginDropdown(false); // Close the dropdown
    };

    const handleCloseUserLoginModal = () => {
        setShowUserLoginModal(false);
    };

    const [value, setValue] = useState()

    const location = useLocation();
    const isPhoto = location.pathname.includes('/selected-house/');
    return (
        <Navbar
            expand="lg"
            className={`custom-navbar responsive-menu bg-white fixed-top topNavigationStyle` }
        >
            <Container fluid>
            <Navbar.Brand href="#">Kodi</Navbar.Brand>         


            </Container>
        </Navbar>
    );
}

export default SpecialNavigation;
