import React from 'react'
import '../assets/scss/RealAgent.scss'
import { Button } from 'react-bootstrap'

function RealAgent() {
    return (
        <div className='real-agent'>
            <div className='d-block text-start'>
                <h2>Do you want us to manage your property?</h2>
                <p>Do you have a property that you want managed or to rent out?</p>
            </div>
            <Button className='btn btn-success'>
                Get Started
            </Button>
        </div>
    )
}

export default RealAgent