import React, { Component } from 'react';
import { Map, Marker, GoogleApiWrapper} from 'google-maps-react';
import SpecialNavigation from './SpecialNavigation';
import MapImage from '../assets/images/listings.png'
import Footer from './Footer';
import { Nav, Navbar, NavDropdown, Container, Image, Dropdown, Modal, Button, Form, InputGroup } from 'react-bootstrap';
import TopMenuFilter from './TopMenuFilter';

class MapComponent extends Component {
  render() {
    const { google, location } = this.props;

    return (
      <>

        <div style={{ width: '100%', height: '100%',position:"static" }}>
          <Map
            google={google}
            className='position-relative'
            zoom={14}
            initialCenter={location}
            style={{ height: '80vh',width:"100%",position:'relative' }} 
          >
            <Marker
              position={location}
              name={'House Location'}
            />
          </Map>
        </div>
      

      </>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDx1JG3xfwVxYr6q7RrjevNC1tCuxfxXd8',
})(MapComponent);
