import React, { useEffect, useState, useMemo, useReducer } from 'react';
import Navbar from '../components/NavigationBar';
import '../assets/scss/Home.scss';
import FeaturedProperties from './FeaturedProperties';
import HomePageSearch from '../components/HomePageSearch';
import PropertiesCities from './PropertiesCities';
import WhyUs from './WhyUs';
import OurPartners from './OurPartners';
import Footer from '../components/Footer';
import RealAgent from '../components/RealAgent';
import DefaultImage from '../assets/images/default.jpg';

// Create a context to share the background image URL
const BackgroundImageContext = React.createContext();

// Define a reducer function to manage state updates related to background images
function reducer(state, action) {
  switch (action.type) {
    case 'ADD_IMAGE':
      return { ...state, [action.index]: action.image };
    default:
      return state;
  }
}

function Home() {
  // State variables to manage current image index, background images, and loading state
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [backgroundImages, dispatch] = useReducer(reducer, {});

  // State variable to track whether all images have been loaded
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);

  // Generate URLs for the background images using useMemo to optimize performance
  const imageUrls = useMemo(() => {
    const urls = [];
    for (let i = 0; i < 17; i++) {
      urls.push(`https://kodinyumba.app/media/Stock/hd/LandingPage/${i + 1}.jpg`);
    }
    return urls;
  }, []);

  // Function to get a random image URL not used in the current background images
  const getRandomImage = () => {
    const usedImages = Object.values(backgroundImages);
    const remainingImages = imageUrls.filter((image) => !usedImages.includes(image));
    const randomNumber = Math.floor(Math.random() * remainingImages.length);
    return remainingImages[randomNumber];
  };

  // Effect to rotate background images at a 10-second interval
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % Object.keys(backgroundImages).length);
    }, 10000); // Rotate images every 10 seconds

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, [backgroundImages]);

  // Effect to load background images when the component mounts
  useEffect(() => {
    const loadImages = async () => {
      const loadedImages = {};
      for (let i = 0; i < 9; i++) {
        const imageUrl = getRandomImage();
        const image = await loadImage(imageUrl);
        dispatch({ type: 'ADD_IMAGE', index: i, image });
        loadedImages[i] = image;
      }
      dispatch({ type: 'ADD_IMAGE', index: 9, image: loadedImages[0] });

      // Set the loading state to false and indicate that all images are loaded
      setAllImagesLoaded(true);
    };

    loadImages();
  }, []);

  // Function to load an image and return a Promise when it's loaded
  const loadImage = async (url) => {
    return new Promise((resolve) => {
      const image = new Image();
      image.onload = () => resolve(image.src);
      image.src = url;
    });
  };

  // Define the inline CSS styles for the background
  const backgroundStyle = useMemo(() => {
    return {
      backgroundImage: `url('${allImagesLoaded ? backgroundImages[currentImageIndex] : DefaultImage}')`,
      height: '100vh',
      position: 'relative',
      transition: 'opacity 1s ease-in-out',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    };
  }, [backgroundImages, currentImageIndex, allImagesLoaded]);

  
  return (
    <BackgroundImageContext.Provider value={backgroundImages}>
      <div className='homepage' style={backgroundStyle}>
        <Navbar />
        <div className='homePageTexts'>
          <h2>Find Your Dream Home</h2>
          <p>Discover Your Dream Home in Kenya's Beautiful Cities, Starting at just Ksh. 5,000/month, with Exclusive Limited-Time Discounts!</p>
        </div>
        <HomePageSearch />
      </div>
      <FeaturedProperties />
      <PropertiesCities />
      <WhyUs />
      <OurPartners />
      <RealAgent />
      <Footer />
    </BackgroundImageContext.Provider>
  );
}

export default Home;
