import React, { useState, useEffect } from 'react';
import { Nav, Navbar, NavDropdown, Container, Image, Dropdown, Modal, Button, Form } from 'react-bootstrap';
import '../assets/scss/NavigationBar.scss';
import Logo from '../assets/images/logo_alliance.png'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { Link } from 'react-router-dom';
// import Input from 'react-phone-number-input/input'


function NavigationBar() {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // the dropdown login part
  const [showLoginDropdown, setShowLoginDropdown] = useState(false);
  const [showUserLoginModal, setShowUserLoginModal] = useState(false);

  const handleLoginClick = () => {
    setShowLoginDropdown(!showLoginDropdown);
  };

  const handleUserLoginClick = () => {
    setShowUserLoginModal(true);
    setShowLoginDropdown(false); // Close the dropdown
  };

  const handleCloseUserLoginModal = () => {
    setShowUserLoginModal(false);
  };

  const [value, setValue] = useState()


  return (
    <Navbar
      expand="lg"
      className={`custom-navbar responsive-menu ${scrollPosition > 5 ? 'bg-white fixed-top' : 'navbarDisplay'
        }`}
    >
      <Container fluid>


        <Navbar.Collapse id="basic-navbar-nav " className='leftSideBar'>
          <Nav className="">
            <Nav.Link href="#home" className={`${scrollPosition > 10 ? 'custom-top-link' : 'custom-link'}`}>
            </Nav.Link>
            {/* <Nav.Link href="/" className={`${scrollPosition > 10 ? 'custom-top-link' : 'custom-link'}`}>
              Home
            </Nav.Link>

            <Nav.Link href="#home" className={`${scrollPosition > 10 ? 'custom-top-link' : 'custom-link'}`}>
              Rent
            </Nav.Link>
            <Nav.Link href="#home" className={`${scrollPosition > 10 ? 'custom-top-link' : 'custom-link'}`}>
              Sell
            </Nav.Link> */}
            {/* <Nav.Link href="#home" className={`${scrollPosition > 10 ? 'custom-top-link' : 'custom-link'}`}>
              Home Loans
            </Nav.Link>
            <Nav.Link href="#" className={`${scrollPosition > 10 ? 'custom-top-link' : 'custom-link'}`}>
              Agent Finder
            </Nav.Link> */}

          </Nav>
        </Navbar.Collapse>

        <Navbar.Brand href="#">Kodi</Navbar.Brand>         


        {/* <Navbar.Brand href="/" className={`${scrollPosition > 10 ? 'custom-brand' : 'custom-link'}`}>
          <Image src={Logo} />
        </Navbar.Brand> */}
        {/* <Navbar.Toggle aria-controls="basic-navbar-nav" className="custom-toggle" /> */}
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link href="#home" className={`${scrollPosition > 10 ? 'custom-top-link' : 'custom-link'}`}>
            </Nav.Link>
            {/* <Nav.Link href="#home" className={`${scrollPosition > 10 ? 'custom-top-link' : 'custom-link'}`}>
              Our Competitive Advantage
            </Nav.Link>
            <Nav.Link href="#home" className={`${scrollPosition > 10 ? 'custom-top-link' : 'custom-link'}`}>
              Properties
            </Nav.Link>
            <Nav.Link href="#home" className={`${scrollPosition > 10 ? 'custom-top-link' : 'custom-link'}`}>
              Listings
            </Nav.Link>
            <Nav.Link href="#home" className={`${scrollPosition > 10 ? 'custom-top-link' : 'custom-link'}`}>
              Services
            </Nav.Link> */}
            {/* <Nav.Link href="https://web.kodinyumba.com/#/login"  id="basic-nav-dropdown" className={`${scrollPosition > 10 ? 'custom-top-link' : 'custom-link'}`} style={{backgroundColor:'#E6B800',color:"white"}}> */}
            {/* <Dropdown show={showLoginDropdown} onClose={() => setShowLoginDropdown(false)} className='loginAirbnb'>
              <Dropdown.Toggle
                as={Nav.Link}
                className={`${scrollPosition > 10 ? 'custom-top-link' : 'custom-link'} loginIcon`}
                onClick={handleLoginClick}
              >
                <span className="bi bi-list me-2"></span>
                <span className="bi bi-person-circle"></span>
              </Dropdown.Toggle>
              <Dropdown.Menu align="end">
                <Dropdown.Item onClick={handleUserLoginClick}>User Login</Dropdown.Item>
                <Dropdown.Item href='https://web.kodinyumba.com'>Admin Login</Dropdown.Item>
                <Dropdown.Item className='' style={{borderBottom:"1px solid lightgray"}}>My favorites</Dropdown.Item>

                <Dropdown.Item>Help Center</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}

            <Modal show={showUserLoginModal} onHide={handleCloseUserLoginModal}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <h6 className='loginText'>Log in or sign up</h6>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className='loginForm'>
                <h5>Welcome to Alliance Realtors</h5>

                <Form>

                  <div className="phone-input-container mb-3">
                    <label htmlFor="phone-input">Country/Region</label>
                    <PhoneInput
                      id="phone-input"
                      international
                      countryCallingCodeEditable={false}
                      defaultCountry="KE"
                      value={value}
                      onChange={setValue}
                      // disabled
                      
                    />
           
         

                    <p style={{ fontSize: "0.8rem", marginTop: "0.3rem" }}>We’ll call or text you to confirm your number. Standard message and data rates apply. </p>
                    <Link className='text-dark' style={{ fontSize: "0.8rem" }}>Privacy Policy</Link>
                  </div>
                  <Button variant="primary" className='text-white w-100' type="">
                    Continue
                  </Button>
                </Form>
              </Modal.Body>

            </Modal>

            <NavDropdown title="Dropdown" id="basic-nav-dropdown" className="custom-dropdown d-none">
              <NavDropdown.Item href="#action/3.1" className="custom-dropdown-item">
                Property
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2" className="custom-dropdown-item">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3" className="custom-dropdown-item">
                Something
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4" className="custom-dropdown-item">
                Separated link
              </NavDropdown.Item>
            </NavDropdown>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
