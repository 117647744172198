import React from 'react'
import SpecialNavigation from '../components/SpecialNavigation'
import Footer from '../components/Footer'
import '../assets/scss/AllPhotos.scss'
import { Container, Row, Col, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function Allphotos() {
    return (
        <>
            <Link to='/selected-house/2'>
                <p className='topNavigation'><i class="bi bi-arrow-left-circle"></i></p>

            </Link>
            <SpecialNavigation />
            <div className='all-photos'>
                <Container className='mb-3'>
                    <Row>
                        <Col md={12}>
                            <Image src='https://kodinyumba.app/media/Stock/House/house1.jpg' />
                        </Col>

                        <Col md={6} className='photodouble' style={{ marginTop: "-7rem" }}>
                            <Image src='https://kodinyumba.app/media/Stock/House/house2.jpg' />
                        </Col>

                        <Col md={6} style={{ marginTop: "-7rem" }}>
                            <Image src='https://kodinyumba.app/media/Stock/House/house3.jpg' />
                        </Col>

                        <Col md={12} style={{ marginTop: "-3.8rem" }}>
                            <Image src='https://kodinyumba.app/media/Stock/House/house4.jpg' />
                        </Col>

                        <Col md={6} style={{ marginTop: "-8.5rem" }}>
                            <Image src='https://kodinyumba.app/media/Stock/House/house5.jpg' />
                        </Col>

                        <Col md={6} style={{ marginTop: "-8.5rem" }}>
                            <Image src='https://kodinyumba.app/media/Stock/House/house6.jpg' />
                        </Col>x
                        <Col md={6} >
                            <div>
                                <Image src='https://kodinyumba.app/media/Stock/House/house7.jpg' style={{ marginTop: "-4rem" }} />
                                <Image src='https://kodinyumba.app/media/Stock/House/house8.jpg' style={{ marginTop: "0.5rem" }} />

                            </div>
                        </Col>
                        <Col md={6}>
                            <Image src='https://kodinyumba.app/media/Stock/House/house9.jpg' style={{ marginTop: "-4rem", height: "75vh" }} />
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </>
    )
}

export default Allphotos