import React, { useState, useEffect } from 'react'
import '../assets/scss/ListingPage.scss'
import { Button, Card, Container, Form, FormControl, Image, Modal, Carousel } from 'react-bootstrap';
import SideMenu from '../components/SideMenu';
import NavigationBar from '../components/NavigationBar';

import { Link, useParams } from 'react-router-dom'
import Footer from '../components/Footer';
import SpecialNavigation from '../components/SpecialNavigation';
import TopMenuFilter from '../components/TopMenuFilter';
import Heart from '../assets/images/heart__1_.png'
import Heart2 from '../assets/images/heart.png'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../assets/scss/TopMenuFilter.scss'
import Star from '../assets/images/star.png'
import Map from '../assets/images/map.png'

function ListingPage() {
    const { id } = useParams();
    const [showSidebar, setShowSidebar] = useState(true);

    function toggleSidebar() {
        setShowSidebar(!showSidebar);
    }
    // loading status
    const [isLoading, setIsLoading] = useState(true)
    const [showModal, setShowModal] = useState(false);

    // related to apartment
    const [selectedApartment, setSelectedApartment] = useState(null);
    const [selectedApartmentImage, setSelectedApartmentImage] = useState({});

    // counted house
    const [houseCount, setHouseCount] = useState({});

    // apartments of the landlord
    // const [landlordApartment, setLandlordApartment] = useState([])

    // the list of dummy apartment data
    const landlordApartment = [
        {
            id: '1',
            name: 'Johari Apartments',
            units: '10',
            occupancy: '50',
            income: '50,000',
            defaulters: '1',
            location: 'Runda',
            county: "Kiambu",
            ratings: "2.3",
            rent: " Ksh. 15,000 - Ksh. 35,000",
        },
        {
            id: '2',
            name: 'Grey Heights Apartments',
            units: '1',
            occupancy: '5',
            income: '5,000',
            defaulters: '0',
            location: 'Umoja II',
            county: "Nairobi",
            ratings: "4.9",
            rent: " Ksh. 30,000 - Ksh. 65,000"


        },
        {
            id: '3',
            name: 'Clairomnt Apartment',
            units: '21',
            occupancy: '55',
            income: '70,000',
            defaulters: '6',
            location: 'Kenyatta Road',
            county: "Nairobi",
            ratings: "1.6",
            rent: "Ksh. 65,000"



        },
        {
            id: '4',
            name: 'Vintage Apartment',
            units: '16',
            occupancy: '89',
            income: '70,000',
            defaulters: '2',
            location: 'Jogoo Road',
            county: "Nairobi",
            ratings: "4.0",
            rent: " Ksh. 10,000 - Ksh. 45,000"



        },
        {
            id: '5',
            name: 'Willemy Apartments',
            units: '12',
            occupancy: '45',
            income: '71,000',
            defaulters: '3',
            location: 'Ruai',
            county: "Nairobi",
            ratings: "3.8",
            rent: "Ksh. 20,000"



        },
        {
            id: '6',
            name: 'Bandari Apartments',
            units: '2',
            occupancy: '51',
            income: '27,000',
            defaulters: '0',
            location: 'Kiambu',
            county: "Kiambu",
            ratings: "1.5",
            rent: "Ksh. 15,000"



        }
    ]

    // get the location of the window
    // Check if you are on the listing page
    if (window.location.pathname === '/listing-page') {
    }

    // filter based on the property location
    const [selectedPropertyLocation, setPropertyLocation] = useState(null);

    // Create a Set to store unique location status
    const uniquePropertyLocation = new Set();

    // Filter houses array to get unique rent amounts
    const uniqueLocation = landlordApartment.filter((location) => {
        if (!uniquePropertyLocation.has(location.location)) {
            uniquePropertyLocation.add(location.location);
            return true;
        }
        return false;
    });

    // on search of the properties get to list the properties
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedRow, setSelectedRow] = useState(null);

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
        setSelectedRow(null); // Reset selected row when user starts a new search
    };

    const filteredData = landlordApartment.filter((item) => {
        // Filter by selected rent
        if (selectedPropertyLocation && item.location !== selectedPropertyLocation) {
            return false;
        }
        const searchableFields = Object.values(item).join(" ").toLowerCase();
        return searchableFields.includes(searchQuery.toLowerCase());
    });
    // design the table to display 10 each per table
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 8;

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = currentPage === totalPages - 1 ? filteredData.length : startIndex + itemsPerPage;
    const displayedItems = filteredData.slice(startIndex, endIndex);

    // randomise the images for my properties
    const [backgroundImage, setBackgroundImage] = useState('');
    useEffect(() => {
        const getRandomImage = () => {
            let storedImage = localStorage.getItem('backgroundImage');
            if (!storedImage) {
                storedImage = '';
            }

            let randomNumber = Math.floor(Math.random() * 10) + 1;
            while (randomNumber === 0 || randomNumber === -1 || storedImage.endsWith(`${randomNumber}.jpg`)) {
                randomNumber = Math.floor(Math.random() * 10) + 1;
            }

            const imageUrl = `https://kodinyumba.app/media/Stock/hd/Property/apartment${randomNumber}.jpg`;
            setBackgroundImage(imageUrl);
            localStorage.setItem('backgroundImage', imageUrl);
            setIsLoading(false);
        };

        getRandomImage();
    }, []);


    // randomise for each apartment
    const [backgroundImageApartment, setBackgroundImageApartment] = useState({});
    const [showIndicators, setShowIndicators] = useState(false);

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    const toggleIndicators = () => {
        setShowIndicators(!showIndicators);
      };

    useEffect(() => {
        const getRandomImages = () => {
            const usedImages = Object.values(backgroundImageApartment);
            const availableImages = Array.from({ length: 10 }, (_, index) => index + 1);
            const uniqueRandomImages = [];

            while (uniqueRandomImages.length < 5) {
                const randomNumber = Math.floor(Math.random() * availableImages.length);
                const randomImage = availableImages.splice(randomNumber, 1)[0];

                if (!usedImages.includes(randomImage)) {
                    uniqueRandomImages.push(randomImage);
                }
            }

            return uniqueRandomImages.map((imageNumber) =>
                `https://kodinyumba.app/media/Stock/Property/apartment${imageNumber}.jpg`
            );
        };

        const updateBackgroundImages = () => {
            const updatedImages = { ...backgroundImageApartment };

            for (const unitDetail of landlordApartment) {
                const apartmentId = unitDetail.id;
                if (!updatedImages[apartmentId]) {
                    updatedImages[apartmentId] = getRandomImages();
                }
            }
            setBackgroundImageApartment(updatedImages);
        };

        updateBackgroundImages();
    }, [landlordApartment, backgroundImageApartment]);

    const renderCarouselItems = (apartmentId) => {
        const uniqueImages = backgroundImageApartment[apartmentId] || [];

        return uniqueImages.map((imageUrl, index) => (
            <Slider {...settings}>

                <Image src={imageUrl} alt={`Image ${index + 1}`} fluid />

            </Slider>
        ));
    };
    // <Slider {...settings}>
    //     {items.map((item, index) => (
    //         <Image src={imageUrl} alt={`Image ${index + 1}`} fluid />

    //     ))}
    // </Slider>

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };
    const [showHeart1, setShowHeart1] = useState(false);
    const [showHeart2, setShowHeart2] = useState(true);

    const toggleHearts = (event) => {
        event.preventDefault(); 
        setShowHeart1(!showHeart1);
        setShowHeart2(!showHeart2);
    };
    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = () => {
        setIsHovered(true);
      };
    
      const handleMouseLeave = () => {
        setIsHovered(false);
      };
    return (

        <>
            <TopMenuFilter />
            {/* <SideMenu /> */}
            <SpecialNavigation />
            {/* <div className='dashboard_topheader'>
                <NavigationBar />

            </div> */}

            <div className='property'>

                <div className='topSelectedProperty' style={{ backgroundImage: `url(${backgroundImage})` }}>
                    {id === 'All' ?
                        <>
                            <p className='properties' style={{ padding: "2.5rem" }}>{id} PROPERTIES</p>

                        </>
                        :
                        <>
                            <p className='properties'>PROPERTIES</p>

                            <span className='properties'>{id}</span>
                        </>

                    }


                </div>
                {/* the cards that have the houses and their properties */}
                <div className='housesCards'>

                    {displayedItems.map((apartmentDetail, index) =>

                        <Card key={index.id} className='house'>
                            <Card.Body>

                                <Link key={apartmentDetail.id} to={`/selected-property/${apartmentDetail.id}`} className='text-danger text-decoration-none'>
                                    <div className="image-container">
                                        {/*                                         
                                            <Image
                                                src={backgroundImageApartment[apartmentDetail.id]}
                                                className="property-grid"
                                            /> */}
                                        <Slider {...settings} interval={null}>{renderCarouselItems(apartmentDetail.id)}</Slider>
                                        <div className='heartBadge' onClick={toggleHearts}>
                                            {showHeart1 && <img src={Heart} alt="Heart" />}
                                            {showHeart2 && <img src={Heart2} alt="Heart2" />}
                                        </div>

                                        <div className="badge">{apartmentDetail.units} Units</div>

                                        <div className='d-flex justify-content-between'>
                                            <p className='text-muted text-start mt-2 w-100' style={{ fontSize: "0.7rem" }}>{apartmentDetail.location},{apartmentDetail.county}</p>
                                            <div className='d-flex justify-content-end w-100'>
                                                <Image src={Star} className='mt-1' style={{ width: "8%", height: "35%" }} />
                                                <p className='mt-1 text-secondary fw-bold' style={{ fontSize: "0.9rem", marginLeft: "0.2rem" }}>{apartmentDetail.ratings}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <p className='houseName' style={{ clear: "left", margin: "0" ,marginTop:"-1rem"}}>{apartmentDetail.name}</p>
                                    <p className='text-secondary float-start ' style={{ fontSize: "0.9rem" }}>{apartmentDetail.rent}</p>

                                </Link>



                            </Card.Body>
                        </Card>
                    )}


                </div>

                {/* the pagination of the cards */}
                {landlordApartment.length > 8 ?
                    <div className="pagination" style={{ marginLeft: "14rem" }}>
                        <button onClick={handlePrevPage} disabled={currentPage === 0}>
                            &lt;
                        </button>
                        <span>{`${startIndex + 1}-${endIndex} of ${landlordApartment.length}`}</span>
                        <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                            &gt;
                        </button>
                    </div>

                    :
                    <>
                    </>}

            </div>
            <Button className='text-white bg-dark border-0 bottomButtonMap'>
                <Link to={'/map'} className='text-white text-decoration-none'>
                Show map
                </Link>
                
                <Image src={Map} style={{width:"20%",marginLeft:"0.2rem"}} />
            </Button>
            <Footer />

        </>
    )
}

export default ListingPage




