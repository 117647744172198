import React from 'react'
import { Col, Image, Row, Card } from 'react-bootstrap'
import Miami from '../assets/images/miami.jpg'
import LA from '../assets/images/LA.jpg'
import Newyork from '../assets/images/NY.jpeg'
import Florida from '../assets/images/Florida.jpg'
import Kakamega from '../assets/images/kakamega.jpg'
import Property from '../assets/images/product_charges.png'
import General from '../assets/images/general.png'
import Assets from '../assets/images/assets_.png'
import '../assets/scss/PropertiesCities.scss'
import { Link } from 'react-router-dom'

function PropertiesCities() {
    const images = [
        {
            image: Miami,
            city: 'Nairobi',
            properties: '102'
        },
        {
            image: LA,
            city: 'Kisumu',
            properties: '50'
        },
        {
            image: Newyork,
            city: 'Narok',
            properties: '17'
        },
        {
            image: Florida,
            city: 'Nakuru',
            properties: '60'
        },
        {
            image: Kakamega,
            city: 'Kakamega',
            properties: '08'
        },
    ]
    return (
        <div className='properties_cities'>
            {/* top part to display the services */}
            <h2 className='mt-3 fw-bold fs-1 mb-1'>What we offer</h2>
            <p>Get the <b className='fw-bold' style={{ color: "#1A3A46" }}>Best Services</b> from us</p>
            <Row className=''>
                <Col md={8} xm={12} sm={12}>
                    <div className='image-cities-container'>
                        <Image src={Property} alt='New York' />
                        <div className='bottomImageDisplay'>
                            <span className='imageText'>PROPERTY & SERVICE CHARGE MANAGEMENT</span>
                        </div>
                    </div>
                </Col>


                <Col md={4} xm={12} sm={12}>
                    <div className='image-cities-containertwo'>
                        <Image src={General} />
                        <div className='bottomImageDisplay'>
                            <span className='imageText'>PROPERTY & ASSET VALUATION</span>
                        </div>
                    </div>
                </Col>

                <Col md={12}>
                    <div className='image-full'>
                        <Image src={Assets} />
                        <div className='bottomImageDisplay'>
                            <span className='imageText'>GENERAL REAL ESTATE CONSULTANCY</span>
                        </div>
                    </div>
                </Col>

            </Row>
            {/* display the cities */}
            <h2 className='mt-3 fw-bold fs-1'>Find properties in these cities</h2>
            <p>Unlocking Your <b className='fw-bold' style={{ color: "#1A3A46" }} >Perfect Place </b>,City by City.</p>
            <Row style={{ width: "95%", padding: "1.5rem" }}>
                {images.map((city) => (
                    <Col lg={2} md={2} sm={12} xm={12} className='columCities' key={city.city}>
                        <Link to={`/listing-page/${city.city}`} className='text-decoration-none'>
                            <div className='image_col'>
                                <div className='image_container' style={{ backgroundImage: `url(${city.image})` }}>
                                    <Card className='cities_card'>
                                        <h3>{city.city}</h3>
                                        <p>{city.properties} Properties</p>
                                    </Card>
                                </div>
                            </div>
                        </Link>
                    </Col>


                ))}


            </Row>
        </div>
    )
}

export default PropertiesCities